body{margin:0px!important; padding:0px; font-family: 'Open Sans', sans-serif; font-size:16px}
a{text-decoration:none;}
p{padding:0px 0px 10px 0px; margin:0px; text-align:justify;}
h1,h2,h3,h4,h6{padding:0px; margin:0px 0px 10px 0px; }
h1{font-size:25px}
h2{font-size:23px}
h3{font-size:21px}
h4{font-size:18px}
.clear{clear:both;}
.menuleft{float:left;width:8%;text-align:center;   
    padding-top: 22px;}
.MuiAutocomplete-popper{width:800px!important;left:70px!important}
.invoice ul,.invoiceleft ul{margin:0;padding:0;}
.invoice li{display:inline-block;margin: 10px 1%;vertical-align:top;    width: 23%;}
.invoiceleft li{display:inline-block;margin: 10px 1%;vertical-align:top;    width: 48%;}
.logohead{text-align:center;color:#fff}
.logohead h1{font-size:35px}
.invoiceright {
    float: right;
    width: 41%;
    margin-right: 8%;
}.invoiceleft {
    float: left;
    width: 50%;
   /* margin: 0px 3%;*/
}

.addtitle{color:#6084d5}
.Primarybuttons {
	background-color: #20a8d8 !important;
	color: #fff;
	display: inline-block;
	padding: 5px;
}
.mainorder{background-color:#fff;width:96%;margin:10px auto}
.addtitle .MuiSvgIcon-root{vertical-align:middle;cursor:pointer;}
.datepicker .MuiGrid-container{display:inline}
.datepicker .MuiGrid-container .MuiInputBase-input{

    border: 1px solid #c4c4c4;
  padding:10px 5px!important;
    border-radius: 5px;

}   .inputleft{float:left;width:48%}
   .inputright{float:right;width:48%;}
.scancodestyle,.scancodestyle .MuiInputBase-root{    width: 100%;display: block!important;}
	.newcust{color:#43a2ff;cursor:pointer;}
	.closeright{float:right;cursor:pointer;}
	.custleft {
    float: left;
    width: 45%;
    margin-left: 4%;
}
.searchmaster .MuiSelect-outlined.MuiSelect-outlined {
 
    padding-right: 70px!important;
}



.primary {
   text-align: center;
    background-color: #4dbd74;
   padding: 4px 10px;
    display: inline-block;
    color: #fff;
	margin: 0 3%;
	 cursor: pointer;
}

.primary a {
    color: #fff;
}


.secondary {
    
    text-align: center;
    background-color: #f86c6b;
	padding: 4px 10px;
    display: inline-block;
    color: #fff;
	 cursor: pointer;
	
}

#secondary {
    
    text-align: center;
    background-color: #f86c6b;
	padding: 4px 10px;
    display: inline-block;
    color: #fff;
	 cursor: pointer;
	
}

.otherbtns {
    
    text-align: center;
    background-color: #ddd;
	padding: 4px 10px;
    display: inline-block;
    color: #fff;
	 cursor: pointer;    margin: 0 3%;
	
}
.tabbg{background-color:#e4e5e6;margin:20px 0px;
    border-spacing: 1px;
    border-collapse: unset;}
.tabbg td{background-color:#fff}
.tabbg th{background-color:#fff}
.secondary svg,.otherbtns svg {
    color: #fff;
	font-size: 18px;
}

.primary svg {
    color: #fff;
	font-size: 18px;
}


.tableright {
    float: right;
    width: 72%;
	background-color:#fff;margin-right: 1%;
}

.formleft {
    float: left;
    width: 25%;
	background-color:#fff;
	margin-left: 1%;

}

.main{width:98%;margin:auto;}
.productbarcode{width:50%;margin:auto;text-align:center;background-color:#fff;margin-top:10px;padding:8px 0}
.productbarcode p{text-align:center}
.MuiButton-containedPrimary {
    color: #fff;
    background-color: #4dbd74!important;
	font-size: 12px!important;
}

.userform li {
    display: inline-block;
    width: 48%;
	margin:1%;
}

.userform ul{padding:0px;margin:0px;}

.formleft p {
    margin: 0px;
    padding: 0px;
}

.backbutton a{float:right!important;}

.userform select {
   
    width: 100%;
}

.taxformleft{float:left;width:48%;}


.taxtableright{float:right;width:48%;}

.taxformleft ul {
    padding: 0px;
    margin: 0px;
}

.taxformleft li {
    display: inline-block;
    padding: 2px 1%;
}

.orgleft{display:table-cell;
    width: 26%;vertical-align: top;
    background-color: #fff;border:15px solid #e4e5e6;border-right:0px
  }
	.panelpad{  padding: 10px 3%;}

.orgright{display:table-cell;
    width: 64%;vertical-align: top;
    background-color: #fff;
    border:15px solid #e4e5e6;}

.orgright ul{padding:0px;margin:0px;}

.orgright li{display:inline-block;width:45%;padding:2px 2%}

.orgright select {
    padding-right: 175px!important;
    width: 100%;
}

.address {
    width: 94%;
    margin: auto;
}

.customerform li {
    display: inline-block;
    width: 23%;
    padding: 2px 1%;
}
.customerform ul {
    padding: 0px;
    margin: 0px;
}

.customeraddress li {
    width: 48%;
}

.customeraddress ul {
    padding: 0px;
    margin: 0px;
}



.orgright select {
    padding-right: 189px!important;
}

.customerform select{padding-right: 115px!important;}




.productright {
  
    width: 100%;background-color: #fff;padding: 10px 0%;
}

.productright ul {
    padding: 0px;
    margin: 0px;
}

.productright li {
    display: inline-block;
    width: 31%;
    padding: 0px 1%;
}

.productaddress ul {
    padding: 0px;
    margin: 0px;
}

.productaddress li {
    display: inline-block;
    width: 48%;
}






.search li {
    display: inline-block;
    width: 29%;
    padding: 1px 2%;
}

.search ul {
    padding: 0px;
    margin: 0px;
}


.search select {
    padding-right: 209px!important;
}

.backbutton {
float: right;}


.formleft select {
    padding-right: 100px!important;
}

.dashboard li {
    display: inline-block;
    width: 28%;
    margin-left: 2.5%;
    margin-right: 2.5%;
   
    background-color: #fff;
    margin-top: 30px;
    margin-bottom: 10px;
}

.dashboard ul {
    padding: 0px;
    margin: 0px;
  
}

.leftimg {
    float: left;
    width: 25%;
    margin: 15px 4.5%;
    background-color: #20a8d8;
    color: #fff;
    text-align: center;
    padding: 10px 0;
}

.count {
    float: right;
    width: 65%;
	margin: 10px 0;
}

.dashboard {
    background-color: #e4e5e6;
	padding: 20px 0;
}

.count h3 {
    color: #20a8d8;
}

.count h4 {
    color: #73818f;
}


.view {
   
    padding: 10px 5%;
    background-color: #f0f3f5;
    color: #20a8d8;
   border-top: 1px solid #c8ced3;
}

.view a{color: #20a8d8;}


.customerview .MuiTableCell-root {border-bottom: none!important;
padding: 8px 0%!important;
}


.formbtns ul{text-align:right;margin:0px;padding:0px}
.formbtns ul li{display:inline-block;width:unset;margin:10px 0}
.MuiFormControl-root {
    border: 0;
    margin: 0;
    display: inline!important;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}
.formleft .MuiFormControl-root,.dropdown .MuiFormControl-root,.formleft .MuiInputBase-root,.dropdown .MuiInputBase-root{ display:inline-block!important;width:100%;}


.searchbg ul {
padding: 0px;}


.MuiOutlinedInput-inputAdornedEnd {
    padding-right: 145px!important;
}



.stockleft {
    float: left;
    width: 20%;
	border-bottom: 1px solid #73818f;
    padding: 6px 0;
}

.stockright {
    float: right;
    width: 80%;
	border-bottom: 1px solid #73818f;
}

.stockright ul {
    padding: 0px;
    margin: 0px;
}

.stockright li {
    display: inline-block;
	padding: 10.5px 2%;
}

.stockleft h3{color:#20a8d8;}
.stockright h3{color:#73818f;}

.stockright p{color:#73818f;}

.menulist {
    
    width: 100%;
    background-color: #2f353a;
    color: #fff;
}

.menulist li {
    list-style: none;
    padding: 10px 2% 10px 3%;
}

.menulist ul{padding:0px;margin:0px;}

.contentright {width:100%;background-color: #e4e5e6; padding: 0px 0 10px 0;}

.breadcrump ul{padding:0px;margin:0px;}

.breadcrump li{display:inline-block;padding:20px 0% 20px 0%;color:#20a8d8;}
.breadcrump{background-color:#fff;}
.breadcrump{float:left;width:52%;}

.stockbtns{float: right;
    width: 30%;
    background-color: #fff;
    text-align: right;
    padding: 22px 2%;}
.breadcrumpbg {
    background-color: #fff!important;
}

.validation{color:red;}

.searchmaster ul{padding:0px;margin:0px;}
.searchmaster li{display:inline-block;padding: 10px 1%;}


.searchmaster .MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 80px;
}

.categorysearch ul{padding:0px;margin:0px;}
.categorysearch li{display:inline-block;padding: 10px 3%;}


.customersearch ul{padding:0px;margin:0px;}
.customersearch li{display:inline-block;padding: 10px 3%;}

.MuiOutlinedInput-input {
    padding: 10px 5px!important;
}


.buttons li {
    display: inline-block;
    padding: 2px 2% 2px 4%;
}
.buttons li:last-child{    float: right;}
.buttons ul {
    padding: 0px;
    margin: 0px;
}

#discard{background-color:#f86c6b!important;padding: 5px 2%;}
#submitscheme{background-color:#4dbd74!important;padding: 5px 2%;}

.buttonsaddpage li {
    display: inline-block;
   margin-right:-35%;
}

.buttonsaddpage ul {
    padding: 0px;
    margin: 0px;
}


.headername{background-color:#f0f3f5;color:#000;padding:10px 3%;}


.buttons{background-color:#f0f3f5;padding:10px 0%;}

.menulist a{color:#fff;}

.formleft ul{padding:0px;margin:0px;}
.formleft li{list-style:none;}


.formbgclr {
    background-color: #fff;
    padding: 0px 2%;
}

.mainadoptionbg {background-color:#fff;width:90%;margin:auto;margin:20px;padding: 10px 2%;}

.stockbtns a {
    background-color: #20a8d8;
    border: 1px solid #20a8d8;
    padding: 5px 8%;color:#fff;
	display:inline-block;
	    margin: 0px 1%;
		float:right;
}


.headername p{padding:0px;}

.searchmaster {
    padding: 10px 2%;
}

.menulist img {
    vertical-align: middle;
}
.stockright a{color:#73818f;}

.ConfigurationS img {
    vertical-align: middle;
}
.ConfigurationS ul {
    padding: 0px;margin: 0px;
}
.ConfigurationS li {
    list-style: none;
    padding: 12px 10px;
    border-bottom: 1px solid #e4e5e6;
}
/*.ConfigurationS {
    width: 30%;
    background-color: #fff;
	float:left;    margin: 40px 0;
}*/
.ConfigurationS {
    width: 33%;
    background-color: #fff;
	float:left;   margin-top: 20px;
}
.ConfigurationS h5 {
    background-color: #f0f3f5;
    padding: 20px 2%;margin: 0px;
}

.ConfigurationS a{color:#20a8d8;}
.orgform {
    background-color: #e4e5e6;
    margin: 50px 5%;
}
.buttonsaddpagee li {
    width: 8%;
}

.searchbg {
    background-color: #fff; padding: 10px 1%;;
}

.search {
    background-color: #fff;padding: 10px 1% 10px 1%;
}
.customersearch{
    background-color: #fff;padding: 10px 1% 0 1%;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    padding-right: 28px;
}

.stockleft li {
    display: inline-block;
    padding: 1px 4%;
}

.stockleft ul{padding:0px;margin:0px;}
.breadcrump a {
    color: #20a8d8;
}
.count h3 {
    margin: 0px;
}
#vendorclr h3{color:#4dbd74;}
#catclr h3{color:#f86c6b;}
#invoiceclr h3{color:#ffc107;}

.view p {
    padding: 0px;
}
.count p{color:#73818f;}

.footer p {
    text-align: center;
    padding: 20px 0;
    background-color: #f0f3f5;
}
.leftimg#vendorclr {
    background-color: #4dbd74!important;
}

.leftimg#catclr {
    background-color: #f86c6b!important;
}

.leftimg#invoiceclr {
    background-color: #ffc107!important;
}






.upload-btnfile {
  color: transparent;
}
.upload-btnfile::-webkit-file-upload-button {
  visibility: hidden;
}
.upload-btnfile::before {
  content: 'Upload File';
  color: black;
  display: inline-block;
  background-color:#ccc;
  border: 1px solid #000;
  border-radius: 20px;
    padding: 5px 25px 5px 25px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
     
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 35px;
}
.upload-btnfile:hover::before {
  border-color: black;
}
.upload-btnfile:active {
  outline: 0;
}
.upload-btnfiles:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}
.uploads-btnfile {
  color: transparent;
}
.uploads-btnfile::-webkit-file-upload-button {
  visibility: hidden;
}
.uploads-btnfile::before {
  content: 'Import';
  color: black;
  display: inline-block;
  background-color:#fff;
  border: 1px solid #000;
  border-radius: 20px;
    padding: 5px 25px 5px 45px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
     
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 35px;
}
.uploads-btnfile:hover::before {
  border-color: black;
}
.uploads-btnfile:active {
  outline: 0;
}
.uploads-btnfile:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
}


.newcustomer li {
    display: inline-block;
    padding: 2px 3%;
}

.newcustomer ul {
    padding: 0px;
    margin: 0px;
}

.productaddress {
    margin: 1px 1%;
}

.MuiButton-fullWidth {
    width: 30%!important;
}
p.btncenter {
    text-align: center;
}

.adminproduct {
    padding: 10px 2%;
    background-color: #fff;
}

.searchbg li {
    display: inline-block;
    width: 48%;
 
    margin-right: 2%;
   
}
p.btnsearch {
    text-align: left;
}

.loginmaindiv {
    width: 50%;
    margin: 50px auto 50px auto;
}
.logincontents{background-color:#f8f8f8;padding:30px 4%;margin-top:50px;
box-shadow: -2px 5px 15px 3px rgba(219,219,219,0.75);
-webkit-box-shadow: -2px 5px 15px 3px rgba(219,219,219,0.75);
-moz-box-shadow: -2px 5px 15px 3px rgba(219,219,219,0.75);
border-radius:13px
}

p.submitlogin {
    text-align: center;
}

.changepassword {
    width: 60%;
    margin: auto;
}
.loginheading {
    text-align: center;
	   color:#494444;margin-bottom:10px
}

.loginbg{
padding:50px 0;
background-image: linear-gradient(to left top, #061832, #13375a, #1d5a83, #2180ae, #20a8d8);
}
.loginheading h3{border-bottom:1px solid #494444;font-size:25px;display:inline-block}
.changepasswordbg {
    background-color: #fff;
    padding: 10px 1% 10px 1%;
}
a.link {
    color: #000!important;
}

.invoiceview .MuiTableCell-root {border-bottom: none!important;
padding: 8px 0%!important;    
}
.invoicemain{margin-top:197px;background-color: #fff;}

.invoiceprint .MuiTableCell-root {border-bottom: none!important;
padding: 8px 0%!important;    
}
.invoiceview {
	
    background-color: #fff;
   
    width: 97%;

    margin: auto;
 /*   
margin-top:20px;
margin-bottom: 10px;*/
}

.invoiceprint {
	/*margin-top:197px;*/
	margin-left:87px;
    background-color: #fff;
   
   /* width: 97%;*/
   width: 92%;
    margin: auto;
    
/*margin-top:2opx;
margin-bottom: 10px;*/
}

.customerdetails {
    float: left;
    width: 32%;
}

.orderdetails {
    float: right;
    width: 32%;
	/*width:132px;*/
	/*margin-right: 295px;*/
}

.orderdetailsprint {
    float: right;
    /*width: 32%;*/
	/*width:132px;
	margin-right: 295px;*/
}

.customerdetails li {
    list-style: none;
}

.orderdetails li {
    list-style: none;
}

.organization {
    float: left;
    width: 32%;
   /*width:242px;*/
}

.organizationprint {
    float: left;
   /* width: 32%;*/
   /*width:200px;*/
}

.organization li {
    list-style: none;
}

.orderproduct {
    width: 97%;
    margin: auto;
  /* margin-top:57px;
   margin-left:49px;*/
    background-color: #fff;
}

.orderproductprint {
    /*width: 97%;*/
   /* margin: auto;*/
   margin-top:57px;
   margin-left:49px;
    background-color: #fff;
}
/*
.orderproductprint .MuiTableCell-root {border-bottom:0;}
*/
.importoption {
    float: right;
    width: 12%;
    text-align: center;
    background-color: #20a8d8;color:#fff;
}
#print-button{margin: 20px 48%;}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px!important;
}
.MuiSvgIcon-root{vertical-align:middle;cursor:pointer;}	
	.title{cursor:pointer;}
	.printdetails1{display:none;}
	    
		.printfont{font-size:8px;margin-left:20px;}
		.fontprint{font-size:8px;}
		.barcodecenter{margin: 0px 30%;}
		.tblhead{background-color:#f0f3f5;margin-left:5%;}
		.prddetailsleft{float:left; margin-left:3%;width:25%;}
		.prddetailsright{float:right;margin-right: 2%;width:50%;}
		.prddetailsright ul{margin:0;padding:0;}
		.prddetailsright li{display:inline-block;width:25%;
		border: 1px solid #c8ced3;padding: 55px;}
		.prddetailsmain{margin-top:15px;}
		.boxbtmleft {
    top: 55px;
   
    position: relative;
    left: -37px;
}
		.boxtopright{
    position: relative;
    left: 120px;
	bottom:30px;
}
.linkcolor{color:#000;}
.viewicon{
    text-align: center;
    /*background-color: #4dbd74;*/
    padding: 4px 10px;
    display: inline-block;
    /*color: #000;*/
    margin: 0 3%;
    cursor: pointer;
}
.viewicon a{color:#000;}
.prdicondisplay{text-align:center;}
.prdicondisplay a {color:#000;}
.up-load{text-align:center;}
.uptext{text-align:center;}
.validcolor{color:#f10505;}


@media print {
	/* not font-size:10px;*/
	 html, body {font-size:10px;}
}

/*--------------------------------*/
.linkcolor{color:#000;}
.prdicondisplay a{color:#000;}
.prdicondisplay{text-align:center;}

.purchase ul,.purchaseleft ul{margin:0;padding:0;}
.purchase li{display:inline-block;margin: 10px 1%;vertical-align:top;    width: 31%;}

.purchaseright {
    float: right;
    width: 41%;
    margin-right: 8%;
}.purchaseleft {
    float: left;
    width: 50%;
   /* margin: 0px 3%;*/
}


.voucherright{float: right;
    width: 49%;}
	.voucher ul{margin:0;padding:0;}
	.voucher li {
    display: inline-block;
    margin: 10px 1%;
    vertical-align: top;
    width: 48%;
}

.voucherdiv ul{margin:0;padding:0;}
.voucherdiv li {
    display: inline-block;
    margin: 10px 1%;
    vertical-align: top;
    width: 23%;
}

.displaytable{background-color:#fff;padding: 10px 2% 10px 2%;}
#serbtn{margin-top:10px;}
.purprd{ width: 97%;
    margin: auto;  background-color: #fff;}
	.purview{
	/*margin-top:197px;*/
	/*margin-left:87px;*/
    background-color: #fff;
   
    width: 97%;
 /*  width: 92%;*/
    margin: auto;
    
margin-top:20px;
margin-bottom: 10px;
}

.payprintdiv ul{margin:20px;padding:0}
.payprintdiv li{display:block;}
.vouleft{float:left;width:50%;}
.vouright{float:right;width:50%;}
.footertotal{font-weight:bold}
.MuiTableCell-root MuiTableCell-footer footertotal{font-weight:bold}
.MuiTableCell-footer{color: #000 !important;
     font-size: 18px!important; 
	 font-weight:bolder;
   }
   
   
/*------------------Bhumika----------------*/
.MuiTableCell-body {
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
}
#borderdate {border: 1px solid #c4c4c4;
border-bottom: #c4c4c4;
padding: 10px;
    border-radius: 5px;
}



.submitrep{text-align:center;}



.center {
    text-align: center;
    width: 22%;
    margin: auto;
}

.bold b{font-size:13px;color:#000;}

b.bold {
    font-size: 13px;
    color: #000;
}

li.lialignment {
    position: relative;
    top: -10px;
}


.customerdetailsform {
    width: 45%;
    float: left;
}

.orderdetailsform {
    float: right;
    width: 45%;
}
.customerdetailsform li {
    list-style: none;
}

.orderdetailsform li {
    list-style: none;
}

.tobillingreport {
    float: left;
    width: 48%;
    padding: 10px 2%;
    border-right: 1px solid #c8ced3;
	
}
.billingdetail {
    float: right;
    width: 45%;
}

.billingdetail li {
    list-style: none;
}
.tobillingreport li {
    list-style: none;
}


.quotationviewfrom .MuiTableCell-root {border-bottom: none!important;
padding: 0px!important;    
}

.quotationview {
    background-color: #fff;
	border: 1px solid #c8ced3;
}

.quotationviewfrom {
    /* width: 95%; */
    /* margin: auto; */
    
	 border: 1px solid #c8ced3;
	
}

.billingborder {
    border-bottom: 1px solid #c8ced3;
}

.tobillingreport p{padding:0px;}

.billingdetail p{padding:0px;}



.fromdetails {
    border: 1px solid #c8ced3;
    padding: 10px;
}


.reports li {
    list-style: none;
    padding: 12px 10px;
    border-bottom: 1px solid #e4e5e6;
}
.reports {
    width: 33%;
    background-color: #fff;
	float:left;  margin-top: 20px;
    margin-left: 2%;
    margin-right: 2%;
}

.reports h5 {
    background-color: #f0f3f5;
    padding: 20px 2%;margin: 0px;
}

.reports a{color:#20a8d8;}

.reports img {
    vertical-align: middle;
}
.reports ul {
    padding: 0px;margin: 0px;
}



.inventory li {
    list-style: none;
    padding: 12px 10px;
    border-bottom: 1px solid #e4e5e6;
}
.inventory {
    width: 30%;
    background-color: #fff;
	float:right;  margin-top: 20px;
}

.inventory h5 {
    background-color: #f0f3f5;
    padding: 20px 2%;margin: 0px;
}

.inventory a{color:#20a8d8;}

.inventory img {
    vertical-align: middle;
}
.inventory ul {
    padding: 0px;margin: 0px;
}

.bgclr{background-color:#fff;border: 1px solid #e4e5e6;}



.tabbginvoicegst{background-color:#e4e5e6;margin:20px 0px;
    border-spacing: 1px;
    border-collapse: unset; width: 100%;}
.tabbginvoicegst td{background-color:#fff}
.tabbginvoicegst th{background-color:#fff}


.expbtn {
    background-color: #20a8d8;
    color: #fff;
    padding: 10px;
    border: 0;
    margin: 0px 4%;float: right;margin-right:2%;
}

.printreport {
    background-color: #20a8d8 !important;
    color: #fff;
    display: inline-block;
    padding: 7px;
    float: right;
    margin-right: 1%;
}


.reportheading {
    text-align: center;
    border: 1px solid #e4e5e6;
    padding: 8px 0;
	background-color:#fff;
}



/*---------------bhumika sep29-----------------*/

.invoicesearch {
    width: 93%;
    margin: auto;
    text-align: center!important;
    background-color: #fff;
    padding: 0px 2%;
    margin-top: 15px;
    padding-top: 12px;
}

.invoicesearch p{text-align:center;}


#searchform{color:#fff;}

.btnleft{float:left;width:40%;text-align:left;}
.btnright{float:right;width:40%;text-align:right;}
.addbtns {
    text-align: center;
    background-color: green;
    padding: 4px 10px;
    display: inline-block;
    color: #fff;
    cursor: pointer;
    margin: 0 3%;
}
.delsecondary{text-align: center;
    background-color: #20a8d8;
	padding: 3px 7px;
    display: inline-block;
    color: #fff;
	 cursor: pointer;}
	 .MuiTableHead-root bordertbl{border:1px solid gray;}
	 .repfromdetails {
   
    padding: 10px;
	float:left;
	width:45%;
}
.repborder{border: 1px solid #c8ced3;}

.signintab{display:table;width:100%;}
.signinmain{display:table-row;width:100%;}
.signinleft{display:table-cell;vertical-align:middle;width:30%;text-align:center;
}

.signinleft p img {max-width:100%;}
.signinright{display:table-cell;vertical-align:middle;width:70%;
/*height:500px;
background-color:#20a8d8;
border-top-left-radius:180px;
border-bottom-left-radius:180px;
 background: url(./images/blue-bg.png) no-repeat center center fixed;
  background-size: cover;*/
}
.signinleft p{text-align:center;}
.formright h1 {text-align:center;color:#ffffff;}
.signinform{text-align:center;}
#usernamelogin, #passwordlogin,.remember {color:#ffffff;}
.formright{padding-top:150px;padding-bottom:150px;
width:45%;margin:0px auto}
.textinput .MuiOutlinedInput-root {
    position: relative;border:2px solid #fff;
    border-radius: 4px;}
	
.textinput	.MuiInputBase-root {color:#fff;}

	
	
#submitlogin{background-color:#ffffff;color:#20a8d8;border-radius:20px;
padding: 10px 47%;margin-top:20px;}
.textinput {margin-top:50px;}
.textinput .MuiSvgIcon-root{color:#ffffff;}



.alignheader{}
.exchangediv ul{margin:0;padding:0;}
.exchangediv li{display:inline-block;}
.exchangediv li:first-child{width:8%;}
.exchangediv li:last-child{text-align:left;width:62%;margin-right:30%}

.browseleft{float:left;width:45%;}

.browseright{float:right;width:45%;}

.logodiv p {text-align:center}
.logodiv p img {max-width:100%;}
.invform h1{text-align:center;}
.invinput{margin:0px 5%;}
.inptcol upload-btn-wrapper input {text-align:center;}
.invup-load{text-align:center;   margin-left : 10%;}
.bg_image{height:801px;}
.loginlogoimage{width:200px;}

tr {
page-break-before: avoid;
}

.page-break {
display: block;
page-break-before: always;
}
.page-break-always{page-break-after: always;}


@media(max-width:999px)
{
	.formright {
   
    bottom: 30px;
    
}
}


@media(max-width:750px)
{


/*	.signinleft p{
 
    text-align: center;
}
.signinright {
    
}
.signinform{background-color:#20a8d8; padding:20px;}
.signinleft p img{width:50%;}*/
/*.bg_image{display:none;}*/
/*.bg_image{height:250px;}*/

}

@media(max-width:600px)
{
	.organization, .customerdetails{width:50%}
	
	.orderdetails {
    float: none;
    width: 50%;}
 
}
	
	
	



@media(max-width:650px)
{
	/*.formright{position:relative;width:80%;margin-top:200px}*/
	/*.bg_image{display:none;}*/
	
	
	.signinleft {
    display: block;
	text-align: center;
	 width: 100%;}
	
	.signinright {
    display: block;
   
    width: 100%;
}
.bg_image {background-image:none !important;height:0px !important;}	
.signinform{background-color:#20a8d8 !important; }	
.formright {
   
    width: 90% !important;
    
}




.up-load input{text-align:center;}
.invup-load {
 
    margin-left : 20%;
}
}


@media(max-width:500px)
{

  .browseleft{float:none;width:100%;}

.browseright{float:none;width:100%;}  

}


@media(max-width:450px)
{
.organization, .customerdetails, .orderdetails{float:none;width:100%}
	
	
}